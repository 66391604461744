import { CssBaseline } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './app/App';
import ErrorBoundaryHandler from './components/ErrorBoundaryHandler';
import { setupStore } from './store';
import { AuthProvider } from './utils/alert.context';
import { BrandThemeProvider } from './utils/BrandThemeProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<ErrorBoundaryHandler />}>
      <AuthProvider>
        <Provider store={setupStore()}>
          <BrowserRouter>
            <BrandThemeProvider>
              <CssBaseline />
              <App />
            </BrandThemeProvider>
          </BrowserRouter>
        </Provider>
      </AuthProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
