import type { ReactNode } from 'react';
import React, { createContext, useMemo, useState } from 'react';

export interface Alert {
  message: string;
  type: string;
}

export interface IAlertContext {
  alert?: Alert;
  onAlert: (alert: Alert) => void;
  onResetAlert: () => void;
}

export const AlertContext = createContext<IAlertContext>({
  alert: undefined,
  onAlert: () => null,
  onResetAlert: () => null,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [alert, setAlert] = useState<Alert | undefined>(undefined);

  const objectReturn = useMemo(() => {
    return {
      alert,
      onAlert: setAlert,
      onResetAlert: () => setAlert(undefined),
    };
  }, [alert]);

  return (
    <AlertContext.Provider value={objectReturn}>
      {children}
    </AlertContext.Provider>
  );
};
