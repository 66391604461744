import { AppBar, Box, useTheme } from '@mui/material';
import React from 'react';

import { ManguearLogo } from '../components/ManguearLogo';
import useIsMobile from '../hooks/useIsMobile';
import SpaceSizes from '../theme/foundations/spacing/SpaceSizes';

const Header = () => {
  const theme = useTheme();

  const isMobile = useIsMobile();

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Box
        py={SpaceSizes.xxs}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ManguearLogo isMobile={isMobile} />
      </Box>
    </AppBar>
  );
};

export default Header;
