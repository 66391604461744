import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query';

import Env from '../env/env';
import getJWTToken from '../utils/getJWTToken';
import { QUERIES_NO_TOKEN_NEEDED } from './common/noTokenQueries';

export const baseQuery = fetchBaseQuery({
  baseUrl: Env.REACT_APP_BASE_API_URL,
  prepareHeaders: async (headers, api) => {
    const token = getJWTToken();

    const tokenNeeded = !QUERIES_NO_TOKEN_NEEDED.includes(api.endpoint);

    if (token && tokenNeeded) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    headers.set('X-Auth-Token', process.env.LAMBDA_TOKEN);

    return headers;
  },
});

export const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 });
