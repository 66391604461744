import { useContext, useState } from 'react';

import { useLoginMutation } from '../api/auth-admin/Admins.api';
import { LoginUser } from '../api/auth-admin/Admins.types';
import { ErrorBasicType } from '../components/ErrorShower/Error.types';
import { AuthContext } from '../utils/auth.context';

const useLogin = () => {
  const [error, setError] = useState<undefined | ErrorBasicType>();

  const [loginMutation, { isLoading, isSuccess }] = useLoginMutation();
  const { onLogin, isLoggingIn, setIsLoggingIn } = useContext(AuthContext);

  const handleLogin = async (loginUser: LoginUser) => {
    setIsLoggingIn(true);

    await loginMutation(loginUser)
      .then((res) => {
        if ('data' in res) {
          onLogin(res.data);
          setIsLoggingIn(false);
        }
      })
      .catch((err) => {
        setError(err);
      });
  };

  return {
    loginMutation: handleLogin,
    isLoading: isLoading || isLoggingIn,
    isSuccess,
    error,
  };
};

export default useLogin;
