import { Stack, Typography } from '@mui/material';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { AlertCard } from '../components/AlertCard/AlertCard';
import ErrorShower from '../components/ErrorShower/ErrorShower';
import Form from '../components/Form';
import Input from '../components/Input/Input';
import { InputPassword } from '../components/InputPassword/InputPassword';
import LoadingButton from '../components/LoadingButton/LoadingButton';
import useLogin from '../hooks/useLogin';
import SpaceSizes from '../theme/foundations/spacing/SpaceSizes';
import ModalCentredWrapper from '../components/ModalCentredWrapper.tsx/ModalCentredWrapper';

export type Inputs = {
  loginId: string;
  password: string;
};

const LoginView = () => {
  const methods = useForm<Inputs>();
  const [errorCode, setErrorCode] = useState('');

  const { loginMutation, error, isLoading } = useLogin();

  if (error) return <ErrorShower error={error} />;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await loginMutation({
        email: data.loginId,
        pwd: data.password,
      });
    } catch (err) {
      setErrorCode('credentialsError');
    }
  };

  return (
    <ModalCentredWrapper>
      <Form methods={methods} style={{ width: '100%' }} onSubmit={onSubmit}>
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={SpaceSizes.xs}
        >
          <Typography fontWeight="bold" variant="h5" textAlign="center">
            Inicia Sesion en Manguear
          </Typography>
          {isNotNilOrEmpty(errorCode) && (
            <AlertCard
              content="Hubo un problema al iniciar sesion. Intente otra vez"
              id="loginError"
              show={isNotNilOrEmpty(errorCode)}
            />
          )}
          <Input
            errorMessage="Error de mail"
            id="loginId"
            label="Email"
            placeholder="Ingrese su email"
          />
          <InputPassword
            id="password"
            label="Contraseña"
            placeholder="Ingrese su contraseña"
            validate={false}
          />
          <LoadingButton loading={isLoading} type="submit">
            Iniciar Sesion
          </LoadingButton>
        </Stack>
      </Form>
    </ModalCentredWrapper>
  );
};

export default LoginView;
