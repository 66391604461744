import Env from '../../env/env';
import { api } from '../Api';
import { LoggedUserSession, LoginUser } from './Admins.types';

export const adminsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoggedUserSession, LoginUser>({
      query: ({ email, pwd }) => ({
        url: `${Env.REACT_APP_BASE_API_URL_ADMINS}admin-users`,
        method: 'POST',
        body: {
          email,
          pwd,
        },
      }),
    }),
  }),
});

export const { useLoginMutation } = adminsApi;
