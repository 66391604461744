import { Box, Container, useTheme } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HomeView from '../views/HomeView';
import LoginView from '../views/LoginView';
import ProfileView from '../views/ProfileView';
import Header from './Header';
import { Listener } from './Listener';

export const Layout = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Header />
      <Listener />
      <Container component="main" maxWidth={false} sx={{ m: { xs: 0, sm: 2 } }}>
        <Box sx={{ ...theme.mixins.toolbar }} />
        <Routes>
          <Route element={<LoginView />} path="/login" />
          <Route element={<ProfileView />} path="/profile" />
          <Route element={<HomeView />} path="*" />
        </Routes>
      </Container>
    </Box>
  );
};
