import type { ReactNode } from 'react';
import React, { createContext, useMemo, useState } from 'react';

import { LoggedUserSession, UserDetails } from '../api/auth-admin/Admins.types';

export interface IAuthContext {
  userDetails?: UserDetails;
  jwt?: string;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  isActive: boolean;
  setIsLoggingIn: (val: boolean) => void;
  onLogin: (loginUser: LoggedUserSession) => void;
  onLogout: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  userDetails: undefined,
  jwt: undefined,
  isLoggedIn: false,
  isLoggingIn: false,
  isActive: false,
  setIsLoggingIn: () => null,
  onLogin: () => null,
  onLogout: () => null,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [userDetails, setUserDetails] = useState<UserDetails>();
  const [jwt, setJwt] = useState<string>();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const storeCredentials = async (credentials: UserDetails) => {
    try {
      await localStorage.setItem('credentials', JSON.stringify(credentials));
      if (jwt) await localStorage.setItem('token', jwt);
    } catch (error) {
      setIsLoggingIn(false);
    }
  };

  const loginHandler = (loggedUser: LoggedUserSession) => {
    setUserDetails(loggedUser.user);
    setJwt(loggedUser.jwtToken);
    setIsLoggedIn(true);
    storeCredentials(loggedUser.user);
  };

  const logoutHandler = () => {
    setUserDetails(undefined);
    setJwt(undefined);
    setIsLoggedIn(false);
  };

  const returnObject = useMemo(() => {
    return {
      userDetails,
      jwt,
      isLoggedIn,
      isLoggingIn,
      setIsLoggingIn,
      isActive: isLoggedIn,
      onLogin: loginHandler,
      onLogout: logoutHandler,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  return (
    <AuthContext.Provider value={returnObject}>{children}</AuthContext.Provider>
  );
};
