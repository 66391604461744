const getJWTToken = () => {
  const jwt = localStorage.getItem('token');

  if (jwt) {
    return JSON.parse(jwt);
  }
  return null;
};

export default getJWTToken;
